@import-normalize;

/* 既存サービスに埋め込む際はベーススタイルは全て本サイトの指定と統合します */
* {
  box-sizing: border-box
}

:root {
  font-size: 1rem;
  @media (min-width: 768px) {
    font-size: 1.125rem;
  }
}

html, body,
body > div {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans',
  -apple-system, BlinkMacSystemFont, Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #2489ce;
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  outline: 0;

  strong {
    color: currentColor;
  }
}

a:visited {
  color: #639;
}

a:hover,
a:focus {
  text-decoration: underline;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  appearance: none;
}

button:focus-visible {
  outline: 4px solid rgba(105, 165, 249, 0.5);
  outline-offset: 2px;
}