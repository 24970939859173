.App {
    display: flex;
    justify-content: center;
    align-items: center;
    /* html, body, body > dev　に指定した高さを継承 */
    height: inherit;
}

.App-title {
    display: grid;
    grid-template-rows: 1fr auto auto;
    grid-template-columns: 100%;
    min-height: 100vh;
}

.App-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 720px;
    margin-inline: auto;
    padding-inline: 1.5em;
}

.App-conversation-board {
    position: relative;
    width: 100%;
    height: calc(100% - 24px);
    max-height: 800px;
    /* フォントサイズを基準にするため rem指定しています */
    max-width: 52rem;
    /* デバイス幅に応じて見た目を調整したいので vwを採用してます */
    margin-inline: 2vw;
    background: #FFF;
    box-shadow: 0 0 26px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    overflow: clip;
}

.App-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.375rem;
    font-weight: bold;
    color: #fff;
    background: #F7723E;
    padding-block: .75em;

    @media screen and (min-width: 720px) {
        padding-block: .875em;
    }
}

.App-logo {
    width: 220px;
    max-width: 50%;
}

.App-body {
    /* カウントアップテキストとテキストボックスエリアの高さの合算で6.15remとしています */
    height: calc(100% - 6.15rem);
    display: grid;
    grid-template-rows: 1fr 6.15rem;
}

.App-link {
    color: #61dafb;
}

.App-startButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 1rem;
    color: #fff;
    font-weight: bold;
    padding: .75em 2em;
    background: linear-gradient(180deg, #F7733E 0%, #F1581C 100%);
    border: 1px solid #D3551A;
    border-radius: 6px;
    cursor: pointer;
    margin-block: .5em;
    margin-inline: auto;

    @media (min-width: 768px) {
        max-width: 420px;
    }
}

.App-Message-input-aria {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 2rem auto;
    grid-template-areas: "messageCount messageCount"
    "textArea submit";
    padding-inline: 1.25rem;
    align-items: center;
    column-gap: 1rem;
    row-gap: 1rem;

    @media screen and (min-width: 720px) {
        padding-inline: 2rem;
    }
}

.App-Message-input-aria> *:nth-child(1) {
    grid-area: messageCount;
}

.App-Message-input-aria> *:nth-child(2) {
    grid-area: textArea;
}

.App-Message-input-aria> *:nth-child(3) {
    grid-area: submit;
}

@-moz-document url-prefix() {
    .App-body {
        height: calc(100% - 6.75rem);
        display: grid;
        grid-template-rows: 1fr 6.75rem;
    }
    .App-Message-input-aria {
        row-gap: .5rem;
        padding-inline: 1.5rem;
    }
}